import { $ajax, exportMethod } from '../api'

// 产品模块
export default class Product {
    constructor() { }

    static productBath = {
        exportTemplate: 'https://res-more.deebike.com/other/moreprotocol250226.xlsx',
        categoryAdd: '/api/mo-device/product/category/add.action',
        categoryEdit: '/api/mo-device/product/category/edit.action',
        modelAdd: '/api/mo-device/product/model/add.action',
        modelEdit: '/api/mo-device/product/model/edit.action',
        manufacturerAdd: '/api/mo-device/product/manufacturer/add.action',
        manufacturerEdit: '/api/mo-device/product/manufacturer/edit.action',
        productDictionaryList: '/api/mo-device/product/dictionary/list.action',
        productDictionaryAdd: '/api/mo-device/product/dictionary/add.action',
        productDictionaryEdit: '/api/mo-device/product/dictionary/edit.action',
        protocolList: '/api/mo-device/product/protocol/list.action',
        protocolInfo: '/api/mo-device/product/protocol/info.action',
        protocolAdd: '/api/mo-device/product/protocol/add.action',
        protocolEdit: '/api/mo-device/product/protocol/edit.action',
        listDictionary: '/api/mo-device/product/protocol/listDictionary.action',
        searchCodec: '/api/mo-device/product/codec/list.action',
        protocolAdjust: '/api/mo-device/product/protocol/adjust.action',
        originalAdjust: '/api/mo-device/protocol/adjust.action',
        originalInfo: '/api/mo-device/protocol/info.action',
        originalList: '/api/mo-device/protocol/list.action',
        originalAdd: '/api/mo-device/protocol/add.action',
        originalEdit: '/api/mo-device/protocol/edit.action',
        dictionaryList: '/api/mo-device/dictionary/list.action',
        importDictionary: '/api/mo-device/dictionary/import.action',
        monitorAdd: '/api/mo-device/dictionary/monitorAdd.action',
        monitorEdit: '/api/mo-device/dictionary/monitorEdit.action',
        settingAdd: '/api/mo-device/dictionary/settingAdd.action',
        settingEdit: '/api/mo-device/dictionary/settingEdit.action',
        faultAdd: '/api/mo-device/dictionary/faultAdd.action',
        faultEdit: '/api/mo-device/dictionary/faultEdit.action',
        publishProtocol: '/api/mo-device/protocol/publish.action',
        protocolDetail: '/api/mo-device/protocol/detail.action',
    }

    //导出通讯协议
    static exportProtocol = (fileUrl,fileName,suffix) => {
        return exportMethod(fileUrl, fileName, suffix).then(res => res);
    }

    //发布设备通讯协议
    static publishProtocol = (params) => {
        return $ajax.post(`${this.productBath.publishProtocol}`,params).then(res => res.data)
    }

    //新增故障参数
    static faultAdd = (params) => {
        return $ajax.post(`${this.productBath.faultAdd}`,params).then(res => res.data)
    }

    //编辑故障参数
    static faultEdit = (params) => {
        return $ajax.post(`${this.productBath.faultEdit}`,params).then(res => res.data)
    }

    //新增设置参数
    static settingAdd = (params) => {
        return $ajax.post(`${this.productBath.settingAdd}`,params).then(res => res.data)
    }

    //编辑设置参数
    static settingEdit = (params) => {
        return $ajax.post(`${this.productBath.settingEdit}`,params).then(res => res.data)
    }

    //新增监控参数
    static monitorAdd = (params) => {
        return $ajax.post(`${this.productBath.monitorAdd}`,params).then(res => res.data)
    }

    //编辑监控参数
    static monitorEdit = (params) => {
        return $ajax.post(`${this.productBath.monitorEdit}`,params).then(res => res.data)
    }

    //导入协议字典数据
    static importDictionary = (params) => {
        return $ajax.post(`${this.productBath.importDictionary}`,params).then(res => res.data)
    }

    //导出协议字典模板
    static exportTemplate = (fileName,suffix) => {
        return exportMethod(`${this.productBath.exportTemplate}`, fileName, suffix).then(res => res);
    }

    //获取产品协议详情
    static originalAdjust = (params) => {
        return $ajax.post(`${this.productBath.originalAdjust}`,params).then(res => res.data)
    }

    //获取产品协议详情
    static originalInfo = (params) => {
        return $ajax.post(`${this.productBath.originalInfo}`,params).then(res => res.data)
    }

    //获取字典列表
    static dictionaryList = (params) => {
        return $ajax.post(`${this.productBath.dictionaryList}`,params).then(res => res.data)
    }

     //新增协议
    static originalAdd = (params) => {
        return $ajax.post(`${this.productBath.originalAdd}`,params).then(res => res.data)
    }

     //修改协议
    static originalEdit = (params) => {
        return $ajax.post(`${this.productBath.originalEdit}`,params).then(res => res.data)
    }
    
    //协议列表
    static originalList = (params) => {
        return $ajax.post(`${this.productBath.originalList}`,params).then(res => res.data)
    }

    //协议错误纠偏
    static protocolAdjust = (params) => {
        return $ajax.post(`${this.productBath.protocolAdjust}`,params).then(res => res.data)
    }
    
    //新增产品类型
    static categoryAdd = (params) => {
        return $ajax.post(`${this.productBath.categoryAdd}`,params).then(res => res.data)
    }

    //编辑产品类型
    static categoryEdit = (params) => {
        return $ajax.post(`${this.productBath.categoryEdit}`,params).then(res => res.data)
    }

    //新增产品型号
    static modelAdd = (params) => {
        return $ajax.post(`${this.productBath.modelAdd}`,params).then(res => res.data)
    }

    //编辑产品型号
    static modelEdit = (params) => {
        return $ajax.post(`${this.productBath.modelEdit}`,params).then(res => res.data)
    }

    //新增制造商
    static manufacturerAdd = (params) => {
        return $ajax.post(`${this.productBath.manufacturerAdd}`,params).then(res => res.data)
    }

    //编辑制造商
    static manufacturerEdit = (params) => {
        return $ajax.post(`${this.productBath.manufacturerEdit}`,params).then(res => res.data)
    }

    //获取字典列表
    static productDictionaryList = (params) => {
        return $ajax.post(`${this.productBath.productDictionaryList}`,params).then(res => res.data)
    }

    //新增数据字典
    static productDictionaryAdd = (params) => {
        return $ajax.post(`${this.productBath.productDictionaryAdd}`,params).then(res => res.data)
    }

    //编辑数据字典
    static productDictionaryEdit = (params) => {
        return $ajax.post(`${this.productBath.productDictionaryEdit}`,params).then(res => res.data)
    }

    //获取产品协议列表
    static protocolList = (params) => {
        return $ajax.post(`${this.productBath.protocolList}`,params).then(res => res.data)
    }

    //获取产品协议详情
    static protocolInfo = (params) => {
        return $ajax.post(`${this.productBath.protocolInfo}`,params).then(res => res.data)
    }

    //新增产品协议
    static protocolAdd = (params) => {
        return $ajax.post(`${this.productBath.protocolAdd}`,params).then(res => res.data)
    }

    //编辑产品协议
    static protocolEdit = (params) => {
        return $ajax.post(`${this.productBath.protocolEdit}`,params).then(res => res.data)
    }
 
    //获取协议字典
    static listDictionary = (params) => {
        return $ajax.post(`${this.productBath.listDictionary}`,params).then(res => res.data)
    }

    //搜索协议
    static searchCodec = (params) => {
        return $ajax.post(`${this.productBath.searchCodec}`,params).then(res => res.data)
    }

    //获取协议详情
    static protocolDetail = (params) => {
        return $ajax.post(`${this.productBath.protocolDetail}`,params).then(res => res.data)
    }

}